<template>
  <div>
    <div class="beijingt">
      <img src="@/assets/qixingp/banner@2x.png" alt="" />
    </div>
    <div class="wrap">
      <div class="tips">
        <div class="content">
          当前位置：<span
            @click="
              () => {
                this.$router.push('/');
              }
            "
            >网站首页</span
          >>><span>关于我们</span>
        </div>
      </div>
      <div class="ccl">
        <p>走进七星</p>
        <span class="xian"></span>
        <div class="word">
          <img src="@/assets/qixingp/tu@2x.png" alt="" />
          <p v-html="words"></p>
        </div>
      </div>
      <div class="cclr">
        <p>我们的荣誉</p>
        <span class="xian"></span>
        <div class="juzz">
          <img src="@/assets/qixingp/6@2x.png" alt="" class="img1" />
          <img src="@/assets/qixingp/1@2x.png" alt="" class="img2" />
          <img src="@/assets/qixingp/4@2x.png" alt="" class="img3" />
          <img src="@/assets/qixingp/7@2x.png" alt="" class="img4" />
          <img src="@/assets/qixingp/5@2x.png" alt="" class="img1" />
          <img src="@/assets/qixingp/8@2x.png" alt="" class="img2" />
          <img src="@/assets/qixingp/9@2x.png" alt="" class="img3" />
          <img src="@/assets/qixingp/3@2x.png" alt="" class="img4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      words: "青岛七星国际会展科技有限公司是一家集展览展示、展厅及博物馆建设、软件系统研发、模块化展具生产销售于一体的实体企业，公司为青岛市会展行业协会、会展经济研究会理事单位，连年荣获青岛市优秀展览公司的称号。公司拥有国家级展陈展馆设计施工一级资质，拥有著作权及发明专利37项，2021年荣获青岛市 “专精特新”企业，2022年获评“高新技术”企业。",
      title: {
        top: "文化建设先行者",
        bottom: "深度发掘文化内涵，提炼展示亮点，打造专属特色文化",
      },
    };
  },
  created() {},
  methods: {
    pushRouter(item) {
      this.$router.push(item);
    },
    toDetail(id) {
      this.$router.push({ path: "/culturalDetail", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_introduction", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.words = res.data;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  // padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.list {
  width: 1200px;
  margin: 0 auto;
  padding-top: 48px;
  .left {
    position: relative;
    float: left;
    display: inline-block;
    width: 260px;
    box-shadow: 0px 0px 20px 0px rgba(47, 49, 51, 0.08);
    span {
      display: block;
      width: 260px;
      height: 64px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #1156be;
      text-align: center;
      &:nth-child(1) {
        background: #424242;
      }
      &:nth-child(2) {
        margin: 0 auto;
        width: 229px;
        margin-top: 50px;
        line-height: 64px;
        border-bottom: 1px solid #eeeeee;
      }
      &:nth-child(3) {
        color: #222222;
        line-height: 64px;
      }
    }
    .loca {
      width: 180px;
      height: 69px;
      background: #1156be;
      opacity: 0.8;
      position: absolute;
      top: 23px;
      left: 40px;
      p {
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #f6f6f6;
        margin-top: 10px;
      }
    }
  }
  .right {
    display: inline-block;
    box-shadow: 0px 0px 20px 0px rgba(47, 49, 51, 0.08);
    width: 850px;
    margin-left: 15px;
    padding: 24px 35px;
    img {
      width: 100%;
      &:nth-child(6) {
        margin-top: 54px;
      }
    }
    .p1 {
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      margin-top: 42px;
    }
    .p2 {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      margin-top: 17px;
    }
    .p3 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 23px;
      letter-spacing: 0.9px;
      text-indent: 2em;
      &:nth-child(4) {
        margin-top: 59px;
      }
    }
  }
  .clearfix:after {
    content: ""; /*伪元素必须写的属性*/
    display: block; /*插入的元素必须是块级*/
    height: 0; /*不要看见这个元素*/
    clear: both; /*核心代码清除浮动*/
    visibility: hidden; /*不要看见这个元素*/
  }
}
.beijingt {
  width: 100%;
  height: 445px;
  img {
    width: 100%;
    height: 100%;
  }
}
.ccl {
  width: 1200px;
  margin: 0 auto;
  margin-top: 69px;
  p {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #0059ab;
    text-align: center;
  }
  .xian {
    display: block;
    margin: 0 auto;
    width: 72px;
    height: 5px;
    background: #0059ab;
    margin-top: 14px;
  }
  .word {
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: inline-block;
      width: 492px;
      height: 332px;
      margin-right: 30px;
    }
    p {
      display: inline-block;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
      text-indent: 2em;
      width: 660px;
      text-align: left;
    }
  }
}
.cclr {
  width: 100%;
  margin-top: 69px;
  padding-top: 69px;
  background: url("~@/assets/qixing/矩形 1226.png");
  background-size: cover;
  padding-bottom: 202px;
  p {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .xian {
    display: block;
    margin: 0 auto;
    width: 72px;
    height: 5px;
    background: #ffffff;
    margin-top: 14px;
  }
}
.juzz {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .img1 {
    width: 159px;
    height: 223px;
    margin-top: 128px;
    margin-right: 30px;
  }
  .img2 {
    width: 300px;
    height: 224px;
    margin-top: 128px;
    margin-right: 30px;
  }
  .img3 {
    width: 339px;
    height: 228px;
    margin-top: 128px;
    margin-right: 30px;
  }
  .img4 {
    width: 300px;
    height: 224px;
    margin-top: 128px;
  }
}
</style>