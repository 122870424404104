<template>
  <div>
    <div class="beijingt">
      <img src="@/assets/qixingp/ep_banner@2x.png" alt="" />
    </div>
    <div class="wrap">
      <div class="tips">
        <div class="content">
          当前位置：<span
            @click="
              () => {
                this.$router.push('/');
              }
            "
            >网站首页</span
          >>><span>环保展具</span>
        </div>
      </div>
      <div class="content mat">
        <!-- @click="toDetail(v.id)" -->
        <div
          class="ccde"
          v-for="v in tableData"
          :key="v.id"
          
        >
          <div class="pic">
            <img :src="v.cover" alt="" />
          </div>
          <div class="word">
            <p>{{ v.title }}</p>
          </div>
        </div>
        <!-- <div class="pageNav">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="9"
            prev-text="<上一页"
            next-text="下一页>"
            layout="prev, pager, next, total, jumper"
            :total="total"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
import tableDataJson from '@/assets/zj.json';

export default {
  components: {},
  data() {
    return {
      page: 1,
      total: 0,
      tableData: tableDataJson.map(item => ({
        ...item,
        cover: require(`@/assets/zj/${item.cover}`) // 转换路径为 Webpack 模块
      })),
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/exhibitionDetail",
        query: { item: id },
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail", query: { item: id } });
    },
    // getData() {
    //   const that = this;
    //   const data = {
    //     page: that.page,
    //     limit: 6,
    //   };
    //   that.$http
    //     .postApi("/qx_zj_plan_list", data)
    //     .then((res) => {
    //       if (res.data.code === 200) {
    //         console.log(res);
    //         that.tableData = res.data.data.list;
    //         console.log("22222", that.tableData);
    //         that.total = res.data.data.count;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
// /deep/.el-pagination {
//   transform: scale(1.3);
//   padding-right: 140px;
// }
.wrap {
  padding-bottom: 2.81rem;
  // background: #f7f8f8;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 53px;
  .item {
    margin-right: 22px;
    width: 385px;
    height: 344px;
    border: 1px solid #cccccc;
    padding: 15px 15px 15px;
    box-sizing: border-box;
    margin-bottom: 42px;
    cursor: pointer;
    .img {
      width: 100%;
      height: 269px;
      background-color: #eee;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .title {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      color: #333;
    }
  }
  .item:nth-child(3n) {
    margin-right: 0;
  }
}
.beijingt {
  width: 100%;
  height: 471px;
  img {
    width: 100%;
    height: 100%;
  }
}
.ccde {
  display: inline-block;
  width: 350px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  margin-right: 25px;
  margin-bottom: 32px;
  &:nth-child(3n) {
    margin-right: 0px;
  }
  .pic {
    width: 100%;
    height: 269px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .word {
    padding: 20px 0 5px 0;
    // background: #fff;
    p {
      font-size: 19px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      line-height: 41px;
    }
  }
}
.pics {
  width: 104px;
  height: 28px;
  position: absolute;
  right: 361px;
  bottom: 44px;
}
.shang {
  padding-top: 58px;
}
.mat {
  margin-top: 79px;
}
.pageNav {
  margin: 35px 0 71px;
  text-align: right;
  .el-pagination {
    /deep/.el-pager li {
      border: 1px solid #dbdbdb;
      width: 40px;
      height: 40px;
      position: relative;
      line-height: 40px;
      margin-left: -1px;
      font-weight: 500;
      font-size: 18px;
      &:hover {
        border-color: #1156be;
        z-index: 1;
        color: #1156be;
      }
    }
    /deep/.el-pager li.active {
      border-color: #1156be;
      z-index: 1;
      color: #1156be;
    }
    /deep/button:hover {
      border-color: #1156be;
      color: #1156be;
    }
    /deep/button:disabled {
      color: #c0c4cc;
      background-color: #fff;
      border-color: #dbdbdb;
      cursor: not-allowed;
    }
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 80px;
      height: 40px;
      border: 1px solid #dbdbdb;
    }
    /deep/.btn-prev {
      margin-right: 6px;
    }
    /deep/.btn-next {
      margin-left: 5px;
      margin-right: 23px;
    }
    /deep/button,
    /deep/span:not([class*="suffix"]) {
      line-height: 40px;
    }
  }
}
</style>